export default class PlausibleEvents {
    constructor() {
        const items = <NodeListOf<HTMLElement>>document.querySelectorAll("[data-plausible-event]");
        items.forEach((item) => this.register(item));
    }

    private register(element: HTMLElement) {
        const [event, name] = element.dataset.plausibleEvent.split(":");

        element.addEventListener(event, () => this.triggerEvent(name));
    }

    private triggerEvent(event: string) {
        // @ts-ignore
        window.plausible =
            // @ts-ignore
            window.plausible ||
            function () {
                // @ts-ignore
                if (!window.plausible) window.plausible = { q: [] };
                // @ts-ignore
                (window.plausible.q = window.plausible.q || []).push(arguments);
            };
        // @ts-ignore
        const plausible = window.plausible;
        plausible(event);
    }
}
