const scrollContainers = <NodeListOf<HTMLElement>>document.querySelectorAll(".blog-table-section .scroll-container");

scrollContainers.forEach((container) => {
    if (container.scrollWidth > container.getBoundingClientRect().width) {
        const observer = new IntersectionObserver(
            () => {
                container.scroll({ top: 0, left: 15, behavior: "smooth" });
                setTimeout(() => {
                    container.scroll({ top: 0, left: 0, behavior: "smooth" });
                }, 200);
            },
            { threshold: 1.0 }
        );

        observer.observe(container);
    }
});
