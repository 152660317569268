export default class CopyToClipboard {

    private container : HTMLElement;
    private copyButton: HTMLElement;
    private readonly code: string;

    private readonly unescapeMap = {
        '&amp;' : '&',
        '&lt;' : '<',
        '&gt;' : '>',
        '&quot;' : '"',
        '&#x27;' : "'",
        '&#x60;' : '`'
    };

    constructor( codeSection: HTMLElement ) {
        this.container = codeSection;
        this.copyButton = this.container.querySelector('.copy-button');
        this.code = this.container.querySelector('.code-block-inner').innerHTML;

        if( !navigator.clipboard ){
            this.copyButton.classList.add('disabled');
        }else{
            this.copyButton.addEventListener('click', () => this.copyToClipboard());
        }
    }

    private copyToClipboard(){
        let code = this.unescape(this.code);
        navigator.clipboard.writeText(code);
    }

    // shamelessly stolen from underscore.js
    private unescape(string: string){
        var escaper = (match) => {
            return this.unescapeMap[match];
        };

        // Regexes for identifying a key that needs to be escaped.
        let source = '(?:' + Object.keys(this.unescapeMap).join('|') + ')';
        let testRegexp = RegExp(source);
        let replaceRegexp = RegExp(source, 'g');

        return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
    }

}