export default class DragList {
    private isDown = false;
    private startX;
    private scrollLeft;
    private distance = 0;
    private elements;
    private scrollInterval;
    private resizeTimeout = null;
    private buttonScrollAmount = 33;

    constructor(private slider: HTMLElement, private scrollButtons?: NodeListOf<HTMLElement>) {
        this.elements = slider.querySelectorAll("a");
        this.preventLinkClick();
        this.addDrag();
        if (this.scrollButtons) {
            this.addButtons();
        }
        this.handleResize();
    }

    addButtons() {
        this.scrollButtons.forEach((button) => {
            button.addEventListener("click", (e) => {
                e.preventDefault();
                this.move(button);
            });
            button.addEventListener("mousedown", (e) => {
                e.preventDefault();
                this.startScroll(button);
            });
            button.addEventListener(
                "touchstart",
                (e) => {
                    e.preventDefault();
                    this.startScroll(button);
                },
                { passive: true }
            );
            button.addEventListener(
                "mouseleave",
                (e) => {
                    e.preventDefault();
                    this.stopScroll();
                },
                { passive: true }
            );
            button.addEventListener(
                "mouseup",
                (e) => {
                    e.preventDefault();
                    this.stopScroll();
                },
                { passive: true }
            );
            button.addEventListener(
                "touchend",
                (e) => {
                    e.preventDefault();
                    this.stopScroll();
                },
                { passive: true }
            );
            button.addEventListener(
                "touchcancel",
                (e) => {
                    e.preventDefault();
                    this.stopScroll();
                },
                { passive: true }
            );
        });
        this.distance = 0;
        this.scrollLeft = this.slider.scrollLeft;
        this.setPosition();
    }

    startScroll(button: HTMLElement) {
        window.clearInterval(this.scrollInterval);
        this.scrollInterval = window.setInterval(() => {
            this.move(button);
        }, 50);
    }

    stopScroll() {
        window.clearInterval(this.scrollInterval);
    }

    move(button: HTMLElement) {
        this.distance = button.classList.contains("left") ? this.buttonScrollAmount : -this.buttonScrollAmount;
        this.scrollLeft = this.slider.scrollLeft;
        this.setPosition();
    }

    addDrag() {
        this.slider.addEventListener("mousedown", (e: MouseEvent) => {
            e.preventDefault();
            this.isDown = true;
            this.distance = 0;
            this.startX = e.pageX - this.slider.offsetLeft;
            this.scrollLeft = this.slider.scrollLeft;
        });
        this.slider.addEventListener("mouseleave", (e) => {
            e.preventDefault();
            this.stopDrag();
        });
        this.slider.addEventListener("mouseup", (e) => {
            e.preventDefault();
            this.stopDrag();
        });
        this.slider.addEventListener("mousemove", (e) => {
            if (!this.isDown) return;
            e.preventDefault();
            const amount = e.pageX - this.slider.offsetLeft;
            this.distance = amount - this.startX;
            this.setPosition();
        });
        this.slider.addEventListener(
            "touchmove",
            (e) => {
                e.preventDefault();
                this.setVisibleState();
            },
            { passive: true }
        );
    }

    stopDrag() {
        this.isDown = false;
        this.removeFocus();
    }

    setPosition() {
        this.slider.scrollLeft = this.scrollLeft - this.distance;
        this.setVisibleState();
    }

    setVisibleState() {
        if (this.slider.scrollLeft === 0) {
            this.scrollButtons.forEach((button) => {
                if (button.classList.contains("left")) {
                    button.classList.toggle("disabled", true);
                }
            });
        } else if (this.slider.scrollWidth - Math.ceil(this.slider.scrollLeft) <= this.slider.offsetWidth) {
            this.scrollButtons.forEach((button) => {
                if (button.classList.contains("right")) {
                    button.classList.toggle("disabled", true);
                }
            });
        } else {
            this.scrollButtons.forEach((button) => {
                button.classList.toggle("disabled", false);
            });
        }
    }

    handleResize() {
        window.addEventListener("resize", () => {
            window.clearTimeout(this.resizeTimeout);
            this.resizeTimeout = window.setTimeout(() => this.setVisibleState(), 500);
        });
    }

    preventLinkClick() {
        this.elements.forEach((element: HTMLElement) => {
            element.addEventListener("click", (e) => {
                if (Math.abs(this.distance) > 25) {
                    e.preventDefault();
                }
            });
        });
    }

    removeFocus() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }
}
