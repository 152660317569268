import Navigation from "./Navigation";
import ScrollToTop from "./ScrollToTop";
import CopyToClipboard from "./CopyToClipboard";
import BlogPostsHelper from "./BlogPostsHelper";
import ImageGallery from "./ImageGallery";
import PlausibleEvents from "./PlausibleEvents";
import DragList from "./DragList";
import Glossary from "./Glossary";
import "./SwiperGalleries";
import "./TableScroll";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// navigation
const navContainer = <HTMLElement>document.querySelector(".nav");
const nav = new Navigation(navContainer);

// this is necessary, because clicks apparently aren't always fired on ios
// see https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html for details
const eventType = "ontouchstart" in window ? "touchstart" : "click";
document.querySelector(".nav-open").addEventListener(eventType, () => nav.open(), { passive: true });
document.querySelector(".nav-close").addEventListener(eventType, () => nav.close(), { passive: true });

// scroll to op
const scrollToTopButton = <HTMLElement>document.querySelector("#BackToTop");
const scrollToTop = new ScrollToTop(scrollToTopButton);

// code sections/copy to clipboard
let copyToClipboardInstances = [];
const codeSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".blog-code-section");
codeSections.forEach((cs) => copyToClipboardInstances.push(new CopyToClipboard(cs)));

// blog posts helper
const blogPosts = <NodeListOf<HTMLElement>>document.querySelectorAll(".blog-post");
const blogPostsHelper = new BlogPostsHelper(blogPosts);

const galleryContainers = <NodeListOf<HTMLElement>>document.querySelectorAll(".blog-image-gallery-section");
const galleries = Array.from(galleryContainers).map((g) => new ImageGallery(g));

// draggable lists
const tagList = <HTMLElement>document.querySelector(".blog-archive-section .filter .tag-list");
if (tagList) {
    const tagListScrollButtons = <NodeListOf<HTMLElement>>(
        document.querySelectorAll(".blog-archive-section .filter .scroll-button")
    );
    const draggabeTagList = new DragList(tagList, tagListScrollButtons);
}

// glossary
const glossarySections = <NodeListOf<HTMLElement>>document.querySelectorAll(".glossary-archive-section");
if (glossarySections.length > 0) {
    const glossaries = Array.from(glossarySections).map((g) => new Glossary(g));
}

// collapse
const faqSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".faq-section");
if (faqSections.length > 0) {
    const questionButtons = document.getElementsByClassName("question-button") as HTMLCollectionOf<HTMLElement>;
    const answers = document.getElementsByClassName("answer") as HTMLCollectionOf<HTMLElement>;

    for (let i = 0; i < questionButtons.length; i++) {
        questionButtons[i].addEventListener("click", () => {
            const isActive = questionButtons[i].classList.toggle("active");
            answers[i].classList.toggle("active", isActive);
            questionButtons[i].setAttribute("aria-expanded", String(isActive));
            answers[i].setAttribute("aria-hidden", String(!isActive));

            // Reset all other active answers
            for (let j = 0; j < questionButtons.length; j++) {
                if (
                    j !== i &&
                    questionButtons[j].classList.contains("active") &&
                    answers[j].classList.contains("active")
                ) {
                    questionButtons[j].classList.remove("active");
                    answers[j].classList.remove("active");
                    questionButtons[j].setAttribute("aria-expanded", "false");
                    answers[j].setAttribute("aria-hidden", "true");
                }
            }
        });
    }
}

const events = new PlausibleEvents();
