import Swiper, { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

Swiper.use([Autoplay, Pagination]);

const portfolioSwiper = new Swiper(".portfolio-list", {
    direction: "horizontal",
    loop: false,
    autoHeight: false,
    spaceBetween: 30,
    slidesPerView: 2,
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        992: {
            slidesPerView: 2,
        },
    },
});

const testimonialSwiper = new Swiper(".testimonial-list", {
    direction: "horizontal",
    loop: true,
    autoHeight: false,
    slidesPerView: 1,
    autoplay: {
        delay: 5000,
    },
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
});
