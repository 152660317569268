export default class ImageGallery {
    private spacing = 20;
    private touchThreshold = 100;

    private items: HTMLElement[];
    private indicators: HTMLElement[];
    private slideContainer: HTMLElement;
    private currentIndex = 0;
    private observer: IntersectionObserver;
    private isIntersecting = false;

    // touch
    private startX: number | undefined;
    private currX: number | undefined;

    constructor(private container: HTMLElement) {
        this.items = Array.from(this.container.querySelectorAll(".image-item"));
        this.indicators = Array.from(this.container.querySelectorAll(".indicators .indicator"));
        this.slideContainer = <HTMLElement>this.container.querySelector(".image-list");

        this.items.forEach((item, index) => {
            item.addEventListener("click", () => this.handleClick(index));
            item.addEventListener("touchstart", (e: TouchEvent) => this.handleTouchStart(e));
            item.addEventListener("touchmove", (e: TouchEvent) => this.handleTouchMove(e));
            item.addEventListener("touchend", () => this.handleTouchEnd());
            item.addEventListener("touchcancel", () => this.handleTouchEnd());
        });

        this.indicators.forEach((item, index) => {
            item.addEventListener("click", () => this.handleClick(index));
        });

        document.body.addEventListener("keydown", (e) => this.handleKeyPress(e));

        this.observer = new IntersectionObserver((entries) => this.handleIntersection(entries), { threshold: 0.5 });
        this.observer.observe(this.container);
    }

    public next() {
        if (this.currentIndex < this.items.length - 1) {
            this.goTo(this.currentIndex + 1);
        }
    }

    public prev() {
        if (this.currentIndex > 0) {
            this.goTo(this.currentIndex - 1);
        }
    }

    public goTo(index: number) {
        if (index < 0 || index >= this.items.length) return;

        this.setIndex(index);
    }

    private handleClick(index) {
        if (index === this.currentIndex) {
            //TODO: open fullscreen
        } else {
            this.goTo(index);
        }
    }

    private handleKeyPress(e: KeyboardEvent) {
        if (!this.isIntersecting) return;

        if (e.key === "ArrowLeft") {
            this.prev();
        } else if (e.key === "ArrowRight") {
            this.next();
        }
    }

    private handleIntersection(entries: IntersectionObserverEntry[]) {
        this.isIntersecting = entries.length >= 1 ? entries[0].isIntersecting : false;
    }

    private setIndex(index: number) {
        this.setCurrentState(this.currentIndex, false);
        this.currentIndex = index;
        this.setCurrentState(index, true);
        const offset = this.getOffsetFor(index);
        this.slideContainer.style.transform = `translateX(-${offset}px)`;
    }

    private setCurrentState(index, state) {
        this.items[index].classList.toggle("current", state);
        this.indicators[index].classList.toggle("active", state);
    }

    private getOffsetFor(index: number) {
        let offset = 0;

        for (let i = 0; i < index; i++) {
            const width = this.items[i].getBoundingClientRect().width;
            offset += width + this.spacing;
        }

        return offset;
    }

    private handleTouchStart(e: TouchEvent) {
        this.startX = e.touches[0].clientX;
        this.currX = this.startX;
    }

    private handleTouchMove(e: TouchEvent) {
        if (!this.startX || !this.currX) return;

        this.currX = e.touches[0].clientX;
    }

    private handleTouchEnd() {
        if (!this.startX || !this.currX) return;

        let moved = this.currX - this.startX;

        if (Math.abs(moved) >= this.touchThreshold) {
            if (moved < 0) {
                this.next();
            } else {
                this.prev();
            }
        }

        this.startX = undefined;
        this.currX = undefined;
    }
}
