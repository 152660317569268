export default class BlogPostsHelper {

    private resizeTimeout = null;

    constructor(private blogPosts: NodeListOf<HTMLElement>) {
        this.startTimeout();
        window.addEventListener('resize', () => this.startTimeout());
    }

    private startTimeout() {
        window.clearTimeout(this.resizeTimeout);
        this.resizeTimeout = window.setTimeout(() => this.updateBlogPosts(), 500);
    }

    private updateBlogPosts() {
        this.blogPosts.forEach(blogPost => this.updateBlogPost(blogPost));
    }

    private updateBlogPost(blogPost: HTMLElement) {
        const image = blogPost.querySelector('.blog-image');
        const imageWidth = image.clientWidth;
        const imageHeight = image.clientHeight;

        let scalingFactor;
        if (blogPost.classList.contains('featured')) {
            const postWidth = blogPost.clientWidth;
            scalingFactor = postWidth / imageWidth;
        } else {
            const postHeight = blogPost.clientHeight;
            scalingFactor = postHeight / imageHeight;
        }
        blogPost.style.setProperty('--image-scaling-factor', `${scalingFactor}`);
    }

}
