export default class Glossary {
    private tags: NodeListOf<HTMLElement>;
    private containers: NodeListOf<HTMLElement>;
    private terms: NodeListOf<HTMLElement>;
    private entries: NodeListOf<HTMLElement>;

    private currentTagAndContainerIndex: number = 0;
    private currentTermAndEntryIndex: number | null = null;

    private intersectionObserver: IntersectionObserver;
    private visibleEntries = new Set<Element>();

    private clickEventHandlers = new Map<HTMLElement, () => void>();

    constructor(private section: HTMLElement) {
        this.tags = <NodeListOf<HTMLElement>>this.section.querySelectorAll(".filter .tag");
        this.containers = <NodeListOf<HTMLElement>>this.section.querySelectorAll(".glossary-container");
        this.intersectionObserver = new IntersectionObserver((e) => this.handleIntersectionObserver(e), {
            threshold: 0.5,
        });

        // Check if URL has parameter ?entry= to jump to a specific glossary entry
        if (window.location.search.includes("?entry=")) {
            const entryQuery = window.location.search.split("?entry=")[1];
            this.containers.forEach((container, index) => {
                if (container.dataset.letter.toLowerCase() === entryQuery.substring(0, 1))
                    this.currentTagAndContainerIndex = index;
            });
            if (!window.location.hash) {
                location.assign(window.location.href + `#${entryQuery}`);
            }
        }

        // Check if URL has parameter ?search= to mark first found tag as active
        if (window.location.search.includes("?search=")) {
            this.containers.forEach((container) => {
                if (container.classList.contains("active")) {
                    const matchingTag = Array.from(this.tags).find(
                        (tag) => tag.dataset.letter === container.dataset.letter
                    );
                    if (matchingTag) {
                        matchingTag.classList.add("active");
                    }
                }
            });
        }

        this.getTermsAndEntries(this.containers.item(this.currentTagAndContainerIndex));

        this.tags.forEach((tag, index) => {
            tag.addEventListener("click", () => {
                this.setTagAndContainer(index);
                this.getTermsAndEntries(this.containers.item(this.currentTagAndContainerIndex));
            });
        });
    }

    private handleIntersectionObserver(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.visibleEntries.add(entry.target);
            } else {
                this.visibleEntries.delete(entry.target);
            }
        });

        const topMostElement = Array.from(this.visibleEntries).reduce<Element>(
            (topMostElement: Element | null, element: Element) => {
                if (topMostElement === null) return element;

                if (element.getBoundingClientRect().top < topMostElement.getBoundingClientRect().top) {
                    return element;
                }

                return topMostElement;
            },
            null
        );

        const indexOfTopMostElement = Array.from(this.entries).indexOf(topMostElement as HTMLElement);
        if (indexOfTopMostElement > -1) {
            this.setTermAndEntry(indexOfTopMostElement, false);
        }
    }

    private setTagAndContainer(index: number) {
        this.containers[this.currentTagAndContainerIndex].classList.remove("active");
        this.tags[this.currentTagAndContainerIndex].classList.remove("active");
        this.containers[index].classList.add("active");
        this.tags[index].classList.add("active");
        this.currentTagAndContainerIndex = index;
    }

    private setTermAndEntry(index: number | null, scrollTo = true) {
        if (this.currentTermAndEntryIndex !== null) {
            this.terms[this.currentTermAndEntryIndex].classList.remove("active");
            this.entries[this.currentTermAndEntryIndex].classList.remove("active");
        }

        if (index !== null) {
            this.terms[index].classList.add("active");
            if (scrollTo) {
                this.entries[index].scrollIntoView({ block: "start", behavior: "smooth" });
            }
            this.entries[index].classList.add("active");
        }

        this.currentTermAndEntryIndex = index;
    }

    private getTermsAndEntries(container: HTMLElement) {
        this.unobserveEntries();
        this.unregisterClickHandlers();
        this.setTermAndEntry(null);

        this.terms = <NodeListOf<HTMLElement>>container.querySelectorAll(".glossary-result-list .selection");
        this.entries = <NodeListOf<HTMLElement>>container.querySelectorAll(".glossary-entry");

        this.terms.forEach((term, index) => {
            this.registerClickHandler(term, () => {
                this.setTermAndEntry(index);
            });
        });

        this.setTermAndEntry(0, false);
        this.entries.forEach((e) => this.intersectionObserver.observe(e));
    }

    private unobserveEntries() {
        if (this.entries) {
            this.entries.forEach((e) => this.intersectionObserver.unobserve(e));
        }
        this.visibleEntries.clear();
    }

    private unregisterClickHandlers() {
        Array.from(this.clickEventHandlers.entries()).forEach(([element, handler]) => {
            element.removeEventListener("click", handler);
        });
        this.clickEventHandlers.clear();
    }

    private registerClickHandler(element: HTMLElement, handler: () => void) {
        element.addEventListener("click", handler);
        this.clickEventHandlers.set(element, handler);
    }
}
